import { NgModule } from '@angular/core';

import { NavigationSharedModule } from './navigation.shared.module';
import { EDDefaultHeaderContainerComponent } from './ed-header/ed-default-header/ed-default-header-container/ed-default-header-container.component';
import { EDDefaultHeaderComponent } from './ed-header/ed-default-header/ed-default-header.component';

@NgModule({
  imports: [NavigationSharedModule],
  declarations: [EDDefaultHeaderContainerComponent, EDDefaultHeaderComponent],
  exports: [EDDefaultHeaderContainerComponent],
})
export class NavigationEnergiedirectModule {}

import { NgModule } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import {
  COMMON_JSS_IMPORTS,
  COMMON_JSS_LAZY_IMPORTS,
  CoreModulesCommonClientModule,
  CoreModulesCommonModule,
} from '@innogy/core/modules/common';
import {
  CoreModulesOpenModule,
  OPEN_JSS_IMPORTS,
  OPEN_JSS_LAZY_IMPORTS,
} from '@innogy/core/modules/open';
import { EnergiedirectIconModule } from '@innogy/icons';
import { NavigationEnergiedirectOpenModule } from '@innogy/navigation/ed-open';
import { UtilsModule } from '@innogy/utils-deprecated';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';
import { VERTICAL_SCROLL_OFFSET } from '@core/config-angular';

import { EnergieDirectAppComponent } from './app.component';

export const DEPLOY_PATH_VALUE = 'dist/energiedirect/browser';
export const DEPLOY_PATH_PROVIDER = {
  provide: 'DEPLOY_PATH',
  useValue: DEPLOY_PATH_VALUE,
};

export const VERTICAL_SCROLL_OFFSET_PROVIDER = {
  provide: VERTICAL_SCROLL_OFFSET,
  useValue: 80,
};

export const sharedImports = [
  CoreModulesCommonModule.forRoot({
    appName: 'energiedirect',
    basePath: '',
  }),
  CoreModulesOpenModule,
  EnergiedirectIconModule,
  NavigationEnergiedirectOpenModule,
  JssModule.withComponents(
    [...COMMON_JSS_IMPORTS, ...OPEN_JSS_IMPORTS],
    [...COMMON_JSS_LAZY_IMPORTS, ...OPEN_JSS_LAZY_IMPORTS]
  ),
];

@NgModule({
  imports: [
    CoreModulesCommonClientModule.forRoot(),
    CoreModulesCommonModule.forRoot({
      appName: 'energiedirect',
      basePath: '',
    }),
    UtilsModule.forRoot({
      brandColor: '#5fb624',
      brandColorDark: '#5fb624',
    }),
    ...sharedImports,
  ],
  declarations: [EnergieDirectAppComponent],
  providers: [
    DEPLOY_PATH_PROVIDER,
    VERTICAL_SCROLL_OFFSET_PROVIDER,
    provideClientHydration(),
  ],
  bootstrap: [EnergieDirectAppComponent],
})
export class EnergieDirectAppModule {}

<header class="header">
  <div class="fixed-header">
    <nav class="navbar navbar-light bg-white d-flex">
      <div class="container">
        <ng-template #img>
          <img [src]="'assets/logo.svg?v=2' | deployUrl" alt="logo" />
        </ng-template>

        <ng-template #routeViaLogo>
          <a
            *wlGenericLink="brandLogoLink"
            class="navbar-brand navbar__logo"
            aria-label="Terug naar homepage"
          >
            <ng-template *ngTemplateOutlet="img"></ng-template>
          </a>
        </ng-template>

        <ng-template #actionViaLogo>
          <button
            class="navbar-brand navbar__logo btn p-0"
            (click)="onLogoClicked()"
          >
            <ng-template *ngTemplateOutlet="img"></ng-template>
          </button>
        </ng-template>

        <div class="d-flex justify-content-center">
          <ng-container
            *ngTemplateOutlet="
              brandLogoLink?.href ? routeViaLogo : actionViaLogo
            "
          ></ng-container>

          <div *ngIf="ecmpHeader?.logo" class="navbar-brand border-start h-100">
            <wl-ecmp-header-image
              [ecmpHeader]="ecmpHeader"
            ></wl-ecmp-header-image>
          </div>
        </div>

        <div *ngIf="showHelp" class="d-flex align-items-center">
          <div class="d-none d-md-inline" id="help_needed">
            <button
              class="ed-header-button btn"
              wlAutomationId="helpNeeded"
              title="Hulp nodig?"
              (click)="requestHelp()"
            >
              <span class="d-none d-md-inline">Hulp nodig?</span>
              <fa-icon icon="comments"></fa-icon>
            </button>
          </div>
          <div class="d-md-none">
            <button
              class="ed-header-button btn btn-icon"
              wlAutomationId="helpNeeded"
              title="Hulp nodig?"
              (click)="requestHelp()"
            >
              <fa-icon icon="comments"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>

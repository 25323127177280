import { Component } from '@angular/core';

import { MinimalHeaderComponent } from '../../shared/minimal-header';

@Component({
  selector: 'ed-minimal-header',
  templateUrl: './ed-minimal-header.component.html',
  styleUrls: ['./ed-minimal-header.component.scss'],
})
export class EDMinimalHeaderComponent extends MinimalHeaderComponent {}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import type { JssNavItem, LinkFieldValue } from '@core/jss-models';

@Component({
  selector: 'ed-default-header',
  templateUrl: './ed-default-header.component.html',
  styleUrls: ['./ed-default-header.component.scss'],
})
export class EDDefaultHeaderComponent {
  @ViewChild('stickyHeader', { static: true })
  stickyHeader?: ElementRef;

  @Input()
  language?: string;

  @Input()
  isLoggedIn?: boolean;

  @Input()
  currentNavItems?: JssNavItem[];

  @Input()
  originalNavItems?: JssNavItem[];

  @Input()
  isNavigationAvailable?: boolean;

  @Input()
  isServiceNavigationAvailable?: boolean;

  @Input()
  serviceNavigationTitle?: string;

  @Input()
  serviceNavigationLink?: { label: string; value: LinkFieldValue };

  @Input()
  brandLogoLinkUrl?: string;

  @Input()
  loginUrl?: string;

  @Input()
  loginUrlLabel?: string;

  public history: JssNavItem[] = [];

  isServiceNavigationOpen = false;
  isNavigationOpen = false;

  public selectedItem?: JssNavItem = undefined;

  collapseSideNavigation() {
    this.isNavigationOpen = false;
  }

  nextNavItem(item: JssNavItem) {
    this.selectedItem = item;

    this.history.push(item);
    this.currentNavItems = item.children;
  }

  previousNavItem() {
    if (this.history.length === 1) {
      this.resetNavItems();
    } else if (this.history.length > 1) {
      const previousNavItem = this.history[this.history.length - 2];

      this.currentNavItems = previousNavItem.children;
      this.selectedItem = previousNavItem;

      this.history.pop();
    }
  }

  resetNavItems() {
    this.currentNavItems = this.originalNavItems;
    this.history = [];
    this.selectedItem = undefined;
  }

  onOpenChange(isOpened: boolean) {
    if (isOpened) {
      this.resetNavItems();
    }
  }
}

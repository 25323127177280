<ng-container *ngIf="pageDisplaysNavigation$ | async">
  <ng-container
    *ngTemplateOutlet="
      (pageHasMinimalNavigation$ | async) ? minimalNav : defaultNav
    "
  >
  </ng-container>
</ng-container>

<ng-template #minimalNav>
  <ed-minimal-header-container></ed-minimal-header-container>
</ng-template>

<ng-template #defaultNav>
  <ed-default-header-container></ed-default-header-container>
</ng-template>
